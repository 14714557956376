import React, { useMemo } from 'react';
import { Checkbox, DatePicker, Input, Popover, Table } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/pl';
dayjs.locale('pl');
dayjs.extend(relativeTime);

import { useReports } from '~/queries/useUsers';
import { UserStatsDTO } from '~/dtos';

import { ColumnsType } from 'antd/es/table';

const DetailedPopover = ({ data }: { data: string[] }) => {
  const groupedByDay = useMemo(() => {
    return data.reduce((acc, curr) => {
      const day = dayjs(curr).format('DD.MM.YYYY');
      if (!acc[day]) {
        acc[day] = [];
      }

      acc[day].push(curr);

      return acc;
    }, {} as Record<string, string[]>);
  }, [data]);

  return (
    <Popover
      content={
        <div>
          {Object.entries(groupedByDay).map(([day, items]) => (
            <div key={day}>
              <strong>{day}</strong>: {items.length}
            </div>
          ))}
        </div>
      }
      trigger='hover'
    >
      {data.length}
    </Popover>
  );
};

const COLUMNS: ColumnsType<UserStatsDTO> = [
  {
    title: 'Imię i nazwisko',
    dataIndex: 'user',
    key: 'user',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.user.name.localeCompare(b.user.name),
    render: (_, record) => record.user.name,
  },
  {
    title: 'Badania',
    sorter: (a, b) => a.addedExaminations.length - b.addedExaminations.length,
    dataIndex: 'addedExaminations',
    render: (_, record) =>
      record.addedExaminations.length > 0 ? (
        <DetailedPopover data={record.addedExaminations} />
      ) : (
        0
      ),
    key: 'addedExaminations',
    align: 'center',
  },
  {
    title: 'Obserwacje',
    dataIndex: 'addedObservations',
    key: 'addedObservations',
    render: (_, record) =>
      record.addedObservations.length > 0 ? (
        <DetailedPopover data={record.addedObservations} />
      ) : (
        0
      ),
    sorter: (a, b) => a.addedObservations.length - b.addedObservations.length,
    align: 'center',
  },
  {
    title: 'Wizyty przyjęciowe',
    render: (_, record) =>
      record.addedEntryObservations.length > 0 ? (
        <DetailedPopover data={record.addedEntryObservations} />
      ) : (
        0
      ),
    dataIndex: 'addedEntryObservations',
    key: 'addedEntryObservations',
    sorter: (a, b) =>
      a.addedEntryObservations.length - b.addedEntryObservations.length,
    align: 'center',
  },
  {
    title: 'Obserwacje specjalistyczne',
    render: (_, record) =>
      record.addedPsychoObservations.length > 0 ? (
        <DetailedPopover data={record.addedPsychoObservations} />
      ) : (
        0
      ),
    dataIndex: 'addedPsychoObservations',
    key: 'addedPsychoObservations',
    sorter: (a, b) =>
      a.addedPsychoObservations.length - b.addedPsychoObservations.length,
    align: 'center',
  },
  {
    title: 'Obserwacje pielęgniarskie',
    render: (_, record) =>
      record.addedNurseObservations.length > 0 ? (
        <DetailedPopover data={record.addedNurseObservations} />
      ) : (
        0
      ),
    dataIndex: 'addedNurseObservations',
    key: 'addedNurseObservations',
    sorter: (a, b) =>
      a.addedNurseObservations.length - b.addedNurseObservations.length,
    align: 'center',
  },
  {
    title: 'Leki',
    render: (_, record) =>
      record.addedDrugs.length > 0 ? (
        <DetailedPopover data={record.addedDrugs} />
      ) : (
        0
      ),
    dataIndex: 'addedDrugs',
    key: 'addedDrugs',
    sorter: (a, b) => a.addedDrugs.length - b.addedDrugs.length,
    align: 'center',
  },
  {
    title: 'Epikryzy',
    dataIndex: 'addedEpicrisis',
    render: (_, record) =>
      record.addedEpicrisis.length > 0 ? (
        <DetailedPopover data={record.addedEpicrisis} />
      ) : (
        0
      ),
    sorter: (a, b) => a.addedEpicrisis.length - b.addedEpicrisis.length,
    key: 'addedEpicrisis',
    align: 'center',
  },
];

const { RangePicker } = DatePicker;

export const ReportsView = () => {
  const [showInactive, setShowInactive] = React.useState<boolean>(false);
  const [dateRange, setDateRange] = React.useState<
    [moment.Moment, moment.Moment]
  >([moment().startOf('month'), moment().endOf('month')]);
  const [search, setSearch] = React.useState<string>('');

  const { data, isLoading } = useReports(
    dateRange[0].format('YYYY-MM-DD'),
    dateRange[1].format('YYYY-MM-DD'),
  );

  const filteredData = useMemo(() => {
    return (
      data?.filter((item) => {
        if (!showInactive && item.user.name.includes('(nieaktywny)')) {
          return false;
        }

        return !(
          search && !item.user.name.toLowerCase().includes(search.toLowerCase())
        );
      }) || []
    );
  }, [data, showInactive, search]);

  return (
    <div
      style={{
        marginBottom: 16,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
      }}
    >
      <div
        style={{
          padding: 16,
          paddingBottom: 0,
          width: '100%',
          display: 'flex',
          gap: 8,
        }}
      >
        <Input.Search
          placeholder='Wyszukaj po imieniu lub nazwisku'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        <div
          style={{
            minWidth: 180,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Checkbox
            checked={showInactive}
            onChange={(e) => setShowInactive(e.target.checked)}
          >
            Pokazuj nieaktywnych
          </Checkbox>
        </div>
        <RangePicker
          style={{ width: 400 }}
          value={[dateRange[0], dateRange[1]]}
          onChange={(dates) => {
            if (dates && dates.length === 2 && dates[0] && dates[1]) {
              setDateRange([dates[0], dates[1]]);
            }
          }}
        />
      </div>

      <div style={{ padding: 16 }}>
        <Table<UserStatsDTO>
          rowKey={(record) => record.user.id}
          bordered
          pagination={false}
          columns={COLUMNS}
          dataSource={filteredData}
          loading={isLoading}
        />
      </div>
    </div>
  );
};
