import { message } from 'antd';
import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { queryClient } from '~/constants';
import { PsychoObservationDTO, UpdatePsychoObservationDTO } from '~/dtos';
import { PsychoObservationService } from '~/services/PsychoObservation';
import { handleError } from './common';

export const PSYCHOTHERAPEUTIC_OBSERVATION = 'psychotherapeutic-observation';

export const UPDATE_PSYCHOTHERAPEUTIC_OBSERVATION =
  'update_psychotherapeutic-observation';

export const usePsychoObservation = (id: string) =>
  useQuery<PsychoObservationDTO>([PSYCHOTHERAPEUTIC_OBSERVATION, id], () =>
    PsychoObservationService.getPsychoObservation(id),
  );

export const useUpdatePsychoObservation = (
  id: string,
  onSuccess?: (psychoObservation: PsychoObservationDTO) => void,
) =>
  useMutation<PsychoObservationDTO, AxiosError, UpdatePsychoObservationDTO>(
    UPDATE_PSYCHOTHERAPEUTIC_OBSERVATION,
    (data: UpdatePsychoObservationDTO) =>
      PsychoObservationService.updatePsychoObservation(id, data),
    {
      onSuccess: async (psychoObservation) => {
        await queryClient.refetchQueries([PSYCHOTHERAPEUTIC_OBSERVATION, id]);
        message.success('Edytowano psychoterapeutyczną obserwację');

        if (onSuccess) {
          onSuccess(psychoObservation);
        }
      },
      onError: handleError,
    },
  );

export const usePsychoReports = (
  startDate: string,
  endDate: string,
  userId?: string,
) =>
  useQuery<PsychoObservationDTO[]>(
    ['reports-psycho', startDate, endDate, userId],
    () => PsychoObservationService.getReport(startDate, endDate, userId),
    {
      enabled: !!startDate && !!endDate && !!userId,
    },
  );
