import React from 'react';
import { Table, DatePicker } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/pl';
dayjs.locale('pl');
dayjs.extend(relativeTime);

import { useActivity, useUsers } from '~/queries/useUsers';
import { ColumnsType } from 'antd/es/table';
import { ActivityDTO } from '~/dtos';
import { VisitLink } from '~/components/VisitLink';

export const ActivityView = () => {
  const [selectedDate, setSelectedDate] = React.useState(moment());
  const { data: users } = useUsers();

  const { data, isLoading } = useActivity(
    selectedDate.startOf('date').format('YYYY-MM-DD HH:mm:ss'),
    selectedDate.endOf('date').format('YYYY-MM-DD HH:mm:ss'),
  );

  const columns: ColumnsType<ActivityDTO> = [
    {
      title: 'Dodane przez',
      dataIndex: 'addedById',
      key: 'addedById',
      render: (data) => {
        const foundUser = users?.find((u) => u.id === data);
        return foundUser ? foundUser.name : 'Unknown';
      },
    },
    {
      title: 'Typ',
      dataIndex: 'type',
      render: (type: string) => {
        switch (type) {
          case 'examination':
            return 'Badanie';
          case 'observation':
            return 'Obserwacja';
          case 'nurseObservation':
            return 'Obserwacja Pielęgniarska';
          case 'entryObservation':
            return 'Obserwacja Przyjęciowa';
          case 'psychoObservations':
            return 'Obserwacje Specjalistyczne';
          case 'drug':
            return 'Lek';
          case 'epicrisis':
            return 'Epikryza';
          default:
            return 'Nieznany';
        }
      },
      key: 'type',
    },
    {
      title: 'Data dodania',
      dataIndex: 'date',
      key: 'date',
      render: (date: string) => dayjs(date).format('DD-MM-YYYY HH:mm'),
    },
    {
      title: 'Pacjent i wizyta',
      dataIndex: 'visitId',
      key: 'visitId',
      render: (visitId: string) => <VisitLink visitId={visitId} />,
    },
  ];

  return (
    <div
      style={{
        marginBottom: 16,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
      }}
    >
      <div
        style={{
          padding: 16,
          paddingBottom: 0,
          width: '100%',
          display: 'flex',
          gap: 8,
        }}
      >
        <DatePicker
          value={selectedDate}
          onChange={(date) => setSelectedDate(date || moment())}
          style={{ width: 180 }}
        />
      </div>
      <Table
        columns={columns}
        dataSource={data}
        loading={isLoading}
        rowKey='id'
        pagination={{ pageSize: 10 }}
        style={{ padding: 16 }}
      />
    </div>
  );
};
