import { useVisit } from '~/queries/useVisit';
import { Link } from 'react-router-dom';
import { createPatientVisitRoute, ROUTES } from '~/constants';
import React from 'react';

interface Props {
  className?: string;
  visitId: string;
}

export const VisitLink = ({ className, visitId }: Props) => {
  const { data } = useVisit(visitId);

  return (
    <div>
      {data?.patient?.firstName} {data?.patient?.lastName} -{' '}
      <Link
        to={createPatientVisitRoute(
          ROUTES.dashboard.patient.visit.ROOT,
          data?.patientId + '',
          visitId,
        )}
        style={{
          textDecoration: 'underline',
          fontWeight: 'normal',
          color: 'blue',
        }}
      >
        Przejdź do wizyty
      </Link>
    </div>
  );
};
