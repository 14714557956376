import React from 'react';
import { Card, DatePicker, List, Select } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/pl';
dayjs.locale('pl');
dayjs.extend(relativeTime);

import { useUsers } from '~/queries/useUsers';
import { usePsychoReports } from '~/queries/usePsychoObservation';
import {
  createPatientVisitRoute,
  fullDateWithMonthFormat,
  ROUTES,
} from '~/constants';
import { Link } from 'react-router-dom';

const { RangePicker } = DatePicker;

export const PsychoReportsView = () => {
  const [dateRange, setDateRange] = React.useState<
    [moment.Moment, moment.Moment]
  >([moment().startOf('month'), moment().endOf('month')]);
  const [userId, setUserId] = React.useState<string>('');
  const { data: users } = useUsers();

  const { data, isLoading } = usePsychoReports(
    dateRange[0].format('YYYY-MM-DD'),
    dateRange[1].format('YYYY-MM-DD'),
    userId,
  );

  return (
    <div
      style={{
        marginBottom: 16,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
      }}
    >
      <div
        style={{
          padding: 16,
          paddingBottom: 0,
          width: '100%',
          display: 'flex',
          gap: 8,
        }}
      >
        <Select<string>
          style={{ flex: 1 }}
          value={userId}
          allowClear
          onChange={(value) => setUserId(value)}
          placeholder='Wybierz użytkownika'
        >
          {users?.map((user) => (
            <Select.Option key={user.id} value={user.id}>
              {user.name}
            </Select.Option>
          ))}
        </Select>
        <RangePicker
          style={{ width: 400 }}
          value={[dateRange[0], dateRange[1]]}
          onChange={(dates) => {
            if (dates && dates.length === 2 && dates[0] && dates[1]) {
              setDateRange([dates[0], dates[1]]);
            }
          }}
        />
      </div>

      <div style={{ padding: 16 }}>
        {!data && (
          <p style={{ fontSize: 20 }}>
            Aby zobaczyć raporty, wybierz użytkownika oraz zakres dat.
          </p>
        )}

        <List
          dataSource={data}
          itemLayout='vertical'
          loading={isLoading}
          size='small'
          renderItem={(item) => (
            <List.Item>
              <Card
                title={
                  <b>
                    {item.addedBy.name} -{' '}
                    {`${fullDateWithMonthFormat(item.createdAt)} (${dayjs(
                      item.createdAt,
                    ).fromNow()})`}{' '}
                    - pacjent: {item.visit?.patient?.firstName}{' '}
                    {item.visit?.patient?.lastName} - wizyta z{' '}
                    {fullDateWithMonthFormat(
                      item.visit?.startedAt || new Date(),
                    )}{' '}
                    <Link
                      to={
                        createPatientVisitRoute(
                          ROUTES.dashboard.patient.visit
                            .SPECIALIST_OBSERVATIONS,
                          item.visit?.patient?.id + '',
                          item.visit?.id + '',
                        ) + `?doctorId=${item.addedBy.id}`
                      }
                      style={{
                        textDecoration: 'underline',
                        float: 'right',
                        fontWeight: 'normal',
                        color: 'blue',
                      }}
                    >
                      Przejdź do wizyty
                    </Link>
                  </b>
                }
                type='inner'
                size='small'
              >
                {item.content}
              </Card>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};
